import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class StateService {
  flstute = [];
  stateQc = "";//IQC状态
  stkticketIds = [];
  docList = [];//扫码物料列表
  errorList = [];//拍卡失败返回的列表
  spec = '';
  desc = '';
  tor='';
  constructor() { }
}
