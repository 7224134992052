/**
 * 采购入库单按物料分组表体
 * Created by Hua on 25/10/2018.
 */
import {PurStockInDetails} from './PurStockInDetails';

export class PurStockInGroupedDetails {
  /**
   * 物料编码
   */
  invCode: string;

  invName: string;

  /**
   * 按物料汇总的总数量
   */
  QTY: number;

  dis_qty: number;

  /**
   * 已扫描的标签集合
   */
  tickets: PurStockInDetails[];

  constructor() {
    this.tickets = [];
  }
}
